import React from "react"
import { Link } from "gatsby"
const ConfirmationPage = () => {
  return (
    <div className="columns is-centered mt-5 px-3">
      <div className="column is-half">
        <div className="title">Thank you for sending your enrollment form!</div>

        <br />
        <div className="subtitle">
          A MedGrocer representative will contact you through SMS or email. If
          you don't receive an email within the next working day, please contact
          us at{" "}
          <a
            href="mailto:pulsecare@medgrocer.com?subject=Inquiry on Enrolment Form"
            className="has-text-weight-bold"
          >
            pulsecare@medgrocer.com
          </a>
          .
        </div>
        <Link to="/" className="button is-primary is-medium">
          Return Home
        </Link>
      </div>
    </div>
  )
}

export default ConfirmationPage
