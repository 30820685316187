import React from 'react'
import ConfirmationPage from '../components/forms/confirmation-page/confirmation-page'
import Layout from '../components/layout'
import SEO from '../components/seo'

const Confirmation = props => {
  return (
    <Layout location={props.location}>
      <SEO
        title="Thank you for enrolling!"
        keywords={[
          `novartis`,
          `medgrocer`,
          `pulse care`,
          `entresto`,
          `sacubitril-valsartan`,
        ]}
      />
      <ConfirmationPage />
    </Layout>
  )
}

export default Confirmation
